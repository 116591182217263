.pdf-viewer{
  width: 100%;
  height: 100%;
  object{
    width: 100%;
    height: 100%;
  }
}

.pdf-viewer-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pdf-viewer-header{
  height: 60px;
  min-height: 60px;
  background: #4d4d4d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 1rem;
}

.pdf-viewer-header-tools {
  button {
    width: 2rem;
  }
}

.pdf-viewer-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}