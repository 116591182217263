.recon-modal-container {
    position: fixed;
    left: 0;
    top : 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000000;
}
.recon-modal {
    width: 100%;
    height: 100%;
    background-color: rgba(#000000,0.2);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.recon-modal-body {
    background-color: #ffffff;
    max-width: min(1500px, 80%);
    max-height: min(1200px, 80%);
    padding: 2rem;
    overflow: scroll;
    width: 100%;
}

.recon-modal-header {
    display: flex;
    align-items: center;
    position: relative;
}

.close-modal {
    border-radius: 50%;
    border-width: 0px;
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;

    &:hover {
        opacity: 0.5;
    }
}